import File from "@/scripts/models/file.model";
export default class CampaignProduct {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.name = '';
        this.file = new File();
        this.suggestionValue = null;
        this.initialValue = null;
        this.finalValue = null;
        this.description = '';
        this.active = true;
        this.showQuestions = false;
        this.campaignProductQuestionList = [];
        this.availableTicketsQuantity = null;
        this.rulesAndConditions = '';
        this.fixedValue = null;
        this.initialDate = null;
        this.finalDate = null;
        this.initialFinalDates = [];
        this.ticketsAvailableForSale = 0;
        this.currentQuantity = 1;
        this.openValue = false;
        this.openTickets = false;
        this.customUrl = '';
    }
}