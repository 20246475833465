<template>
  <v-layout row justify-center>
    <v-dialog v-model="giftConfigDialog"
      content-class="giftConfigDialog"
      persistent max-width="730">      
      <v-card
        style="max-height: 100%;"
      >
        <v-card-title class="headline">
          <h1>{{ isTicket ? "Novo Ticket / Cota" : "Configurações de Brindes" }}</h1>
          <v-btn class="close-modal" @click="closeModal">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-card-text style=" padding:20px; justify-content: center;">
          <v-layout column gap-xl ml-3 mt-3 mw-half style="padding: 20px">
              <v-flex class="mb-3">
                <v-select
                  label="Campanha a ser comparada"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  v-model="giftConfig.referralCampaignId"
                ></v-select>
              </v-flex>
                <v-flex class="mb-3">
                    <v-text-field
                    label="Porcentagem mínima de crescimento esperado"
                    v-model="giftConfig.minPercentageMaintenanceTax"
                  ></v-text-field>            
                </v-flex>
                <v-flex class="mb-3">
                    <v-text-field
                    label="Porcentagem máxima de crescimento a ser ofertado após o valor de manutençao"
                    type="text"
                    v-model="giftConfig.maxPercentageMaintenanceTax"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex class="mb-3">
                    <v-text-field
                    label="Porcentagem mínima esperada"
                    type="text"
                    v-model="giftConfig.minPercentageExpected"
                  ></v-text-field>
                </v-flex>
                <v-flex class="mb-3">
                    <v-text-field
                    label="Porcentagem máxima desejada"
                    type="text"
                    v-model="giftConfig.maxPercentageDesired"
                  ></v-text-field>
                </v-flex> -->
              </v-layout>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn
              flat
              round
              color="white"
              class="btn btn-default px-5"
              large
              @click="closeModal"
            >Cancelar</v-btn>
            <v-btn
              flat
              round
              color="white"
              class="btn btn-primary px-5"
              large
              @click="saveGiftConfig"
            >Salvar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import giftConfig from "@/scripts/models/giftConfig.model";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";

// Vuex
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    campaignId: String,
    dialog: Boolean,
    giftConfigDialog: Boolean
  },
  data() {
    return {
      CommonHelper: CommonHelper,
      campaignService: new CampaignService(),
      giftConfig: new giftConfig(),
      campaignGiftList: this.value,
      entityId: null,
      campaigns: [],
      allCampaigns: null,
      selectedCampaignId: '',
    };
  },
  created() {
    this.refreshGiftsConfig(this.$route.query.campaignId);
    this.onValidationSuccessMethod = this.validationSuccess;
    this.validationMethod = this.validate;
    this.entityId = this.$route.query.entityId
    this.listAllCampaigns();
  },
  mounted() {
    this.getGiftConfig();
  },
  watch: {
    giftConfigDialog(val) {
      if (val)
        this.getGiftConfig();
    }
  },
  computed: {
    ...mapGetters([
      "campaignGiftsConfig",
      "campaignLandingPageConfig"
    ]),
  },
  methods: {
    ...mapActions([
      "refreshGiftsConfig",
      "setLandingPageConfig"
    ]),
    closeModal() {
      this.$emit('openGiftDialog');
    },
    getGiftConfig() {
      if (this.campaignGiftsConfig)
        this.giftConfig = this.campaignGiftsConfig
      else 
        this.giftConfig = new giftConfig();
    },
    saveGiftConfig() {
      if (this.campaignGiftsConfig) this.giftConfig.id = this.campaignGiftsConfig.id
      else this.giftConfig.id = 0

      this.giftConfig.campaignId = this.$route.query.campaignId
      this.campaignService.saveGiftConfig(this.giftConfig)
      this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Configuração de brindes salva com sucesso!");
      this.selectedCampaign = '';
      this.giftConfig = new giftConfig();
      this.closeModal();
    },
    listGiftConfigCallback(data) {
      if (data !== null) this.giftConfig = data;
    },
    async listAllCampaigns() {
      const response = await this.campaignService.findBySubdomain()
      this.campaigns = await this.campaignService.listAllByCompanyId(response.company.id)
    },
    getCampaignId(){
      for (const item of this.allCampaigns) {
        if (item.name === this.selectedCampaign) this.giftConfig.campaignId = item.id
      }
    }
  },
};
</script>