<template>
  <v-layout row justify-center>
    <v-dialog 
      content-class="giftConfigDialog"
      v-model="consentTermTextEditDialog"
      persistent 
      max-width="75%"
      max-height="90%"
    >
    <v-card>
      <v-card-title class="headline">
        <h1>Meios de Pagamento - TERMOS DE CONSENTIMENTO</h1>
        <v-btn class="close-modal" @click="$emit('closeConsentTermTextEditDialog')">
          <i class="fal fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-card-text style=" padding:20px; justify-content: center; overflow: auto; max-height: 80vh;">
          <v-layout style="padding: 20px; font-size: 24px; font-weight: 600" column gap-xl ml-3 mt-3>
            <v-list class="p-5">
              <v-list-group
                v-for="paymentMethod in paymentMethods" :key="paymentMethod.id"
                class="mb-5"
            >
              <template v-slot:activator>
                <v-list-tile-content class="mt-3">
                  <v-list-tile-title
                  >{{ paymentMethod.name }}</v-list-tile-title>
                </v-list-tile-content>
              </template>
                <div class="t-area-heart-step mt-2 mb-3">
                  <label>Digite o texto do termo de consentimento para <strong> {{ paymentMethod.name }}</strong></label>
                  <vue-editor
                    v-model="campaignPaymentConsentTermText[paymentMethod.name]"
                    :editor-toolbar="customToolbar"
                    @input="addConsentTermObject(paymentMethod)"
                  />
                </div>
            </v-list-group>
            </v-list>
            <label style="font-size: 14px; text-align: left;">Obs: Se um meio de pagamento não tiver um termo de consentimento personalizado, ele usará o <strong>termo de consentimento padrão</strong>.</label>
          </v-layout>
          
      </v-card-text>
      <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn
        flat
        round
        color="white"
        class="btn btn-primary px-5"
        large
        @click="save"
      >Salvar</v-btn>
      <v-btn
        flat
        round
        color="white"
        class="btn btn-default px-5"
        large
        @click="$emit('closeConsentTermTextEditDialog')"
      >Fechar</v-btn>
          </div>
      </v-card-actions>
      
    </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
// Services
import CampaignService from "@/scripts/services/campaign.service.js";

// Vuex
import { mapGetters, mapActions } from "vuex";

// Editor
import { VueEditor } from "vue2-editor";

export default {
  props: {
    consentTermTextEditDialog: Boolean,
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      campaignService: new CampaignService(),
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      paymentMethods: null,
      text: '',
      campaignPaymentConsentTerm: [],
      campaignPaymentConsentTermText: {}
    };
  },
  created() {
    this.campaignService.listAllPaymentMethod((response) => {
      this.paymentMethods = response
    });
    this.getCampaignConsentTerms()
    this.addTexts
  },
  watch: {
    paymentMethods: {
      handler(val) {
        if (val)
        this.addTexts();
      },
      deep: true
    },
    consentTermsText: {
      handler(val) {
        if (val)
        this.addTexts();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      "campaignLandingPageConfig",
      "consentTermsText"
    ]),
  },
  methods: {
    ...mapActions([
      "getCampaignConsentTerms"
    ]),
    save() {
      this.campaignService.savePaymentMethodConsentTerms(this.campaignPaymentConsentTerm).then(() => {
        this.getCampaignConsentTerms()
        this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Termo de anuência alterado com sucesso!");
      })
    },
    addTexts() {
      if(this.consentTermsText) {
        this.consentTermsText.forEach((item) => {
          const index = this.paymentMethods.findIndex((payment) => item.paymentMethodId === payment.id)
          if (index !== -1) this.campaignPaymentConsentTermText[this.paymentMethods[index].name] = item.paymentMethodConsentTermText
        })
      }
    },
    addConsentTermObject(paymentMethodObj) {
      const pMethodConsentText = this.campaignPaymentConsentTermText[paymentMethodObj.name]
      const itemIndex = this.campaignPaymentConsentTerm.findIndex((item) => item.paymentMethodId === paymentMethodObj.id)
      const getterConsentTermIndex = this.consentTermsText.findIndex((item) => item.paymentMethodId === paymentMethodObj.id)

      if (itemIndex === -1) {
        const pObject = {
          paymentMethodId: paymentMethodObj.id,
          paymentMethodConsentTermText: pMethodConsentText,
          campaignId: this.campaignLandingPageConfig.campaign.id,
          active: true,
          id: this.consentTermsText[getterConsentTermIndex] && this.consentTermsText[getterConsentTermIndex].id ? this.consentTermsText[getterConsentTermIndex].id : 0
        }
        this.campaignPaymentConsentTerm.push(pObject)
      } else if (itemIndex !== -1) {
        this.campaignPaymentConsentTerm[itemIndex].paymentMethodConsentTermText = pMethodConsentText
        if (getterConsentTermIndex && this.consentTermsText[getterConsentTermIndex]) this.campaignPaymentConsentTerm[itemIndex].id = this.consentTermsText[getterConsentTermIndex].id
      }
    }
  }
};
</script>