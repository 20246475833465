export default class CampaignProductQuestion {

  constructor() {
      this.Order = 0;
      this.id = 0;
      this.campaignProductId = 0;
      this.question = '';
      this.active = true;
      this.answer = '';
      this.hasAlternatives = false;
      this.campaignProductQuestionAlternatives = [];
  }
}