export default class CampaignGiftQuestion {

  constructor() {
      this.Order = 0;
      this.id = 0;
      this.campaignGiftId = 0;
      this.question = '';
      this.active = true;
      this.answer = '';
      this.hasAlternatives = false;
      this.campaignGiftQuestionAlternatives = [];
  }
}